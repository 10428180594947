.auth-base{
    position: absolute;
    background-color: #1316206A;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-cont{
    width: 40em;
    /* height: 30vw; */
    padding: 1em;
    border-radius: .5em;
    background-color: #666E9727;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: .5em;
    height: 22em;
}
.auth-cont::before{
    /* content: ""; */
    background-image: url('../assets/images/logo/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: .05;
}
.auth .head{
    display: flex;
    justify-content: space-around;
    gap: 1em;
}
.auth .head button{
    color: var(--txt-gold);
    font-family: oswaldRegular;
    font-size: 20px;
    background-color: #131620A9;
    backdrop-filter: blur(20px);
    padding: 1em;
    width: 100%;
    border-radius: .3em;
}
.auth .head button:hover{
    box-shadow: 0 0 10px #666E974A;
}
.auth .head button.active{
    border: 1px solid #FFC70159;
}
.auth .body{
    width: 100%;
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.auth .form{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}
.auth .inp{
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    padding: .5em;
    border-radius: .5em;
    outline: none;
    color: var(--txt-white);
}

.auth .inp.valid{
    border-color: #FFC70199;
}

.auth .txt{
    background-color: #1316207B;
    border: 1px solid #666E979C;
}
.auth .btn{
    background-color: #FFC70199;
}
.auth .btn:disabled, .auth .btn[disabled=true]{
    opacity: .5;
}


.auth .status{
    position: absolute;
    top: 1em;
    right: 1em;
    padding: .2em 1em;
    border-radius: .5em;
    color: var(--txt-white);
    display: none;
    font-size: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.auth .status.success{
    background-color: #077020;
}
.auth .status.error{
    background-color: #b22f28;
}
.auth .status.active{
    display: block;
}

.verify-main{
    background-color: var(--primary);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ve-cont{
    background-color: #666E9743;
    padding: 1em;
    border-radius: .5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.ve-cont .text-1{
    color: var(--primary);
    font-weight: 700;
    font-size: 18px;
}
.ve-cont .text-2{
    font-family: oswaldRegular;
    font-size: 22px;
    color: var(--txt-gold);
}
.ve-cont .text-3{
    font-family: oswaldRegular;
    font-size: 24px;
    font-weight: 600;
}
@media screen and (max-width : 700px) {
    .auth-cont{
        width: 95%;
    }
    .auth .head button{
        font-size: 18px;
    }
}

@media screen and (max-width : 480px) {
    .auth-cont{
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
    .auth .status{
        width: 90vw;
        right: 0em;
    }
}
.wa-cont{
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
}

.wa-bal-cont{
    background-color: #666E974A;
    padding: .5em 2.5em;
    border-radius: .5em;
}
.wa-bal{
    border-bottom: 2px solid var(--txt-dim);
    width: 100%;
    padding: .2em 2em;
}
.wa-bal span{
    font-family: oswaldRegular;
    font-size: 30px;
    color: var(--txt-gold);
}
.wa-trend{
    text-align: center;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: .1em;
}
.wa-trend span:nth-child(1){
    font-family: oswaldRegular;
    font-size: 14px;
}
.wa-trend span:nth-child(2){
    font-size: 12px;
}
.wa-trend.p span:nth-child(1){
    color: #12c624;
}
.wa-actions-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3em;
    padding: 1em;
}
.wa-action{
    width: 100%;
    text-align: center;
    border-radius: .5em;
    padding: 1.8em 0;
    background-color: #131620AA;
    font-size: 18px;
    transition: .2s ease-in;
}
.wa-action:hover{
    color: var(--txt-gold);
    box-shadow: 0 0 .5em #FFC7011A;
}

.wa-history{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .8em;
}
.wa-history .head{
    font-family: oswaldRegular;
    font-size: 18px;
    color: var(--txt-gold);
}
.wa-history .sep{
    border: 1px solid var(--txt-gold);
}
.wa-hist-lay{
    display: grid;
    grid-template-columns: 50% repeat(2, 25%);
    padding: .3em;
}
.wa-hist-lay p{
    font-family: oswaldRegular;
    font-size: 14px;
}
.wa-hist-head{
    background-color: #131620AA;
}
.wa-hist-head p{
    color: var(--txt-dim);
}
.wa-hist-lay p:nth-child(2){
    text-align: center;
}
.wa-hist-lay p:nth-child(3){
    text-align: right;
}
.wa-hist-entry p:nth-child(3){
    color: var(--txt-gold);
}

.dep{
    position: fixed;
    left: 0;
    top: 0;
}

.dep-cont, .wi-cont{
    height: fit-content !important;
    flex-direction: column;
    gap: 1.5em;
}
.tr-head{
    width: 100%;
    font-family: oswaldRegular;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.tr-pre{
    font-weight: 700;
    font-size: .7em;
    letter-spacing: .5px;
    color: var(--txt-dim);
    background-color: var(--primary);
    padding: .5em;
}
/* .tr .body{

} */
.dep .body{
    display: flex;
    flex-direction: column;
    gap: .5em;
}
.dep-addr-cont{
    border: 1px solid #666E971A;
    padding: .5em;
    display: flex;
    flex-direction: column;
    gap: .1em;
    border-radius: .5em;
}
.dep .type{
    font-size: 12px;
    color: var(--txt-dim);
}
.dep-addr{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: .3em;
}
.dep-addr-txt{
    flex-grow: 1;
    font-family: oswaldRegular;
    letter-spacing: 1px;
    color: var(--txt-gold);
    overflow-x: scroll;
    background-color: #131620AA;
    padding: .2em .5em;
}
.dep-addr-copy{
    background-color: var(--primary);
    padding: .3em .5em;
    border-radius: .3em;
    font-size: 12px;
    color: var(--txt-dim);
}

.tr-end{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tr-action{
    background-color: var(--txt-gold);
    padding: .5em 1.5em;
    font-size: 18px;
    font-family: oswaldRegular;
    color: var(--txt-dim);
    border-radius: .3em;
}
.wi-addr-cont, .wi-amt-cont{
    display: flex;
    gap: 1em;
    justify-content: space-between;
}
.wi-amt-cont{
    gap: .5em;
    width: 50%;
    margin: .5em auto;
}
.wi-addr-cont > *, .wi-amt-cont > *{
    width: 100%;
    background-color: #131620AA;
    font-family: oswaldRegular;
    font-size: 16px;
    padding: .1em .5em;
    outline: none;
}

.wi-amt-cont p{
    white-space: nowrap;
    width: fit-content;
}
.wi-addr-inp{
    flex-grow: 2;
}
.wi-select-token-type{
    width: fit-content;
}

.wi-btn:disabled,.wi-btn[disabled=true]{
    opacity: .5;
}
.wi-modal-txt{
    font-family: oswaldRegular;
    color: var(--txt-dim);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.wi-modal-pre{
    text-align: center;
    font-size: 13px;
}
.wi-modal-pre a{
    color: var(--txt-gold);
    text-decoration: underline;
    font-weight: 600;
}

@media screen and (max-width : 480px) {
    .wa-actions-cont{
        gap: 2em;
        padding: .5em;
    }
    .wa-action{
        padding: 1.4em 0;
        font-size: 14px;
    }
    .wi-amt-cont{
        gap: .3em;
        width: 80%;
    }
    .wi-addr-cont > *, .wi-amt-cont > *{
        font-size: 14px;
    }
}
.jk-main{
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.jk-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.jk-img{
    width: 15em;
    height: 15em;
    background-image: url('../assets/images/sprites/cup.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.jk-tx{
    font-family: bellfortRough;
    font-size: 2em;
    color: var(--txt-gold);
    letter-spacing: 2px;
}

.sp-cont{
    display: flex;
    flex-direction: column;
}
.spin-display{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.spin-area{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    flex: 1;
    gap: 1.5em;
}
.spin-area .head{
    font-family: oswaldRegular;
    font-size: 18px;
    font-weight: 600;
    color: var(--txt-dim);
}

.sp-pointer-holder svg{
    transform: rotate(-135deg);
}

.sp-set-amount-cont{
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #06070a65;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em 1em;
    gap: 2em;
    backdrop-filter: blur(5px);
    border-top-right-radius: 1.2em;
    border-top-left-radius: 1.2em;
}
.sp-set-amount-cont .head{
    font-family: oswaldRegular;
    font-size: 18px;
    /* color: var(--txt-dim); */
}
.sp-set-amount-cont .body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .1em 1em;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.sp-set-amount-cont .pre{
    font-size: 10px;
    width: 100%;
}

.sp-set-amount-cont .body > *{
    width: 100%;
    background-color: #131620AA;
    font-family: oswaldRegular;
    font-size: 20px;
    font-weight: 700;
    padding: .1em .5em;
    outline: none;
    color: var(--txt-dim);
}
.sp-set-amount-cont .body > button{
    background-color: var(--txt-gold);
    width: fit-content;
    border-radius: .3em;
    padding: .1em 1em;
}

.sp-set-amount-cont .body > button[disabled=true], .sp-set-amount-cont .body > button:disabled{
    opacity: .5;
}
.spin-actions{
    width: 100%;
    gap: .5em;
    display: flex;
}
.spin-spin{
    background-color: var(--txt-gold);
    font-family: bellfortRough;
    color: var(--txt-dim);
    padding: .5em 1em;
    width: 100%;
    font-size: 24px;
    border-radius: .5em;
    flex-grow: 2;
}
.spin-close{
    background-color: #851f1f;
    font-family: bellfortRough;
    color: var(--txt-dim);
    padding: .5em 1em;
    width: 100%;
    font-size: 24px;
    border-radius: .5em;
    flex-shrink: 1.5;
}

.spin-spin[disabled = true], .spin-spin:disabled{
    opacity: .3;
}

.spins-left{
    width: 100%;
    text-align: right;
    font-family: bellfortRegular;
    font-size: 30px;
    color: var(--txt-dim);
    position: absolute;
    top: 50%;
    right: 1em;
}

@media screen and (max-width : 480px) {
    .spin-display{
        scale: .75;
    }
    .sp-set-amount-cont{
        position: fixed;
    }
}
@media screen and (max-width : 300px) {
    .spin-display{
        scale: .6;
    }
}


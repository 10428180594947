.ac-head{
    display: flex;
    padding: 1em .1em;
    justify-content: center;
    gap: 3em;
    align-items: center;
}
.ac-avatar-cont{
    position: relative;
}
.ac-avatar{
    width: 10em;
    height: 10em;
    display: block;
    border: 1px solid var(--txt-dim);
    border-radius: 50%;
    background-color: #666E972A;
    background-image: url('../assets/images/sprites/avatar.png');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
}
.ac-ch-avatar{
    position: absolute;
    bottom: 0;
    right: 7px;
}
.ac-name-cont p{
    font-family: oswaldRegular;
    font-size: 25px;
    width: 100%;
    text-align: center;
    color: var(--txt-gold);
}
#app{
    background-color: var(--primary);
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}




/* ------ header ------- */
/* #header{
    
} */
.h-top{
    border: 1px solid #666E9710;
    display: flex;
    padding: 0 1.5em;
    align-items: center;
    justify-content: flex-start;
    gap: 4em;
}
.h-top .icons{
    display: flex;
    gap: 1em;
}
.h-top p{
    color: var(--txt-dim);
    font-size: 10px;
}

.layout{
    display: grid;
    grid-template-columns: 7% 68% 25%;
    justify-content: space-between;
    height: 100%;
}
.layout > *{
    border: 1px solid #666E9710;
    /* display: block; */
}

.main{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    overflow: scroll;

}
#header{
    padding: 0 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    background-color: #13162055;
    backdrop-filter: 20px;
}
/* .under-header{
    padding: 2em;
} */
.org-id{
    display: flex;
    align-items: center;
    gap: .5em;
    font-size: 26px;
    
}
#logo{
    width: 2em;
}
.org-id span{
    font-family: bellfortRough;
}
.org-id span:nth-child(2){
    color: var(--txt-gold);
}
.hd .user{
    display: flex;
    align-items: center;
    gap: 1em;
}
.hd .wallet{
    display: inline-flex;
    justify-content: space-between;
    gap: .2em;
    align-items: center;
    padding: .3em .7em;
    background-color: #20273D;
    border-radius: .3em;
}
.hd .wallet span{
    font-family: oswaldRegular;
    font-size: 12px;
}
.hd .wallet sup{
    font-family: oswaldRegular;
    font-size: 9px;
}

.content{
    background-image: url('../assets/images/bg/bg.png');
    background-size: auto;
    background-clip: border-box;
    background-attachment: fixed;
    background-repeat: no-repeat;
    height: 100%;
    padding-top: 3.5em !important;
    /* overflow-y: scroll !important; */
    /* display: flex;
    flex-direction: column; */
}
.nav{
    display: flex;
    flex-direction: column;
    position: relative;
}
.nav .head{
    /* background-color: #FFC7011A; */
    background-color: #20273D;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav .head img{
    width: 2em;
    border-radius: 50%;
    border: 1px solid var(--txt-gold);
}

.nav .body{
    display: flex;
    flex-direction: column;
    padding: 1.5em 0;
    gap: 1em;
}
.nav .nav-btn{
    display: flex;
    flex-direction: column;
    padding: .3em;
    justify-content: center;
    align-items: center;
    gap: .3em;
    
}
.nav .nav-btn span{
    font-family: oswaldRegular;
    color: var(--txt-dim);
}
.nav .nav-btn.active span{
    color: var(--txt-gold);
}
.nav .end{
    position: absolute;
    bottom: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#pane-open-btn{
    position: absolute;
    transform: translate(-1.5em, .5em);
    width: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--txt-gold);
    opacity: .6;
    display: none;
    
}
#pane-open-btn svg{
    transition: transform .5s ease-in;
}
#menu-open-btn{
    position: absolute;
    background-color: var(--primary);
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    transform: translateX(3em);
    z-index: 1001;
    top: 3.5em;
    display: none;
}

.container{
    padding: 1em;
    /* border: 1px solid red; */
    height: 100%;
    overflow: scroll;
    position: relative;
    /* flex: 1; */
}
@media screen and (max-width : 768px){
    .layout{
        grid-template-columns: 8% 92%;
        height: 100%;
    }
    #pane{
        position: fixed;
        background-color: var(--primary);
        right: 0;
        height: 100%;
        width: 25vw;
        transform: translateX(25vw);
        transition: transform .15s ease-in;
    }
    #pane-open-btn{
        display: block;
    }
    #pane.active{
        transform: translateX(0);
    }
    #pane.active #pane-open-btn{
        opacity: 1;
    }
    #pane.active #pane-open-btn svg{
        transform: rotateZ(180deg);
    }
}
@media screen and (max-width : 768px) and (min-width : 480px) {
    
    .nav .head img{
        width: 1.5em;
    }
    .nav .body{
        padding: 1em 0;
        gap: .5em;
    }
    .nav .nav-btn{
        transform: scale(.6);  
    }
}

@media screen and (max-width : 480px) {
    .layout{
        grid-template-columns: 100%;
        height: 100%;
    }
    #header{
        padding: 0 .5em;
    }
    .org-id{
        gap: .3em;
        font-size: 20px;
    }
    #logo{
        width: 1.8em;
    }
    .hd .user{
        gap: .5em;
    }
    .hd .wallet{
        padding: .3em .5em;
        transform: scale(.7);
    }
    #pane-open-btn{
        transform: translate(-1.5em, 3em);
    }
    #pane{
        right: 0;
        height: 100%;
        width: 75vw;
        transform: translateX(75vw);
        transition: transform .15s ease-in;
    }
    .nav{
        position: fixed;
        z-index: 1000;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: var(--primary);
        transform: translate(-100vw);
        transition: transform .25s ease-in;
    }
    .nav .head{
        width: fit-content;
        padding: 1em;
    }
    .nav .head img{
        width: 2em;
    }
    .nav .body{
        padding: 2em 1em;
        gap: 1em;
        align-items: flex-start;
    }
    .nav .nav-btn{
        flex-direction: row;
        padding: .5em;
        gap: .5em;
        /* transform: scale(.); */
        
    }
    .nav .end{
        position: absolute;
        bottom: 4em;
        padding: 1em;
        border-radius: .5em;
        background-color: #20273D;
    }
    .nav.active{
        transform: translate(0);
    }
    #menu-open-btn{
        display: block;
    }
    .nav.active #menu-open-btn{
        transform: translateX(0);
        z-index: 1001;
    }
}
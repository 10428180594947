.ad-cont{
    padding: .2em;
    /* height: 8em; */
}
.ad-banner{
    height: fit-content;
    width: 80%;
    background-color: var(--primary);
    margin: 0 auto;
}
.ad-banner img{
    width: 100%;
    border-radius: .5em;
    border: 1px solid var(--txt-gold);
}
.home-content{
    margin: 2em auto;
    padding: .5em;
    background-color: #666E971A;
    backdrop-filter: blur(5px);
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.hc .top{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
}

.hc .game{
    width: 100%;
    height: 10em;
    /* border: 1px solid gold; */
    border-radius: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.5s ease;
}
.hc .game:hover{
    background-size: 105%;
}
.hc .game p{
    font-family: oswaldRegular;
    font-size: 18px;
    transition: .5s ease-in;
}
.hc .game:hover p{
    font-size: 24px;
    color: var(--txt-gold);
    font-weight: 600;
}

.hc .game.spin{
    background-image: url('../assets/images/bg/spin.jpeg');
}

.hc .game.jack{
    background-image: url('../assets/images/bg/jack.jpeg');
}
.hc .action{
    width: 100%;
    height: 6em;
    border: 1px solid var(--txt-dim);
    border-radius: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.hc .action p{
    font-family: bellfortBold;
    color: var(--txt-gold);
}
.hc .base{
    display: flex;
    gap: 2em;
    justify-content: space-between;
}
@media screen and (max-width : 768px){
    .home-content{
        gap: 2em;
    }
    .hc .game{
        height: 7em;
    }
    .hc .action{
        height: 4em;
    }
}
@media screen and (max-width : 480px) {
    .hc .top{
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        align-items: center;
    }
    .hc .game{
        width: 80%;
        height: 9em;
    }
}